import React, { Suspense } from 'react'
import { hot } from 'react-hot-loader/root'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CustomStylesApplier from 'components/customStylesApplier'

// Lazy load route components
const LoginFormPage = React.lazy(() => import('pages/authentication/loginFormPage'))
const PasswordLoginPage = React.lazy(() => import('pages/authentication/passwordLoginPage'))
const AppRouter = React.lazy(() => import('components/appRouter'))
const MobileAppLoginPage = React.lazy(() => import('pages/authentication/mobileAppLoginPage'))
const GoogleDrivePickerPage = React.lazy(() => import('pages/external_sync/googleDrivePickerPage'))

// Inline loading spinner for performance reasons
// don't change this to import from other scripts or it'll reduce performance
const LoadingSpinner = () => (
  <div className='vh-100 d-flex align-items-center justify-content-center'>
    <div className='text-center'>
      <div className='LoadingSpinner' />
      <div>Hang on while we get things ready for you...</div>
    </div>
  </div>
)

const App = () => (
  <div className='App'>
    <CustomStylesApplier />
    <BrowserRouter>
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <Route path='/login' component={LoginFormPage} />
          <Route path='/mobile_app_login' component={MobileAppLoginPage} />
          <Route path='/password_login' component={PasswordLoginPage} />
          <Route path='/preboarding_login' component={LoginFormPage} />
          <Route path='/google-drive-picker' component={GoogleDrivePickerPage} />
          <Route path='/' component={AppRouter} />
        </Switch>
      </Suspense>
    </BrowserRouter>
  </div>
)

export default hot(App)
